<template>
  <div class="content">
    <div>
      <p>オンライン麻雀ゲーム『麻雀一番街』（英語名：Riichi City）の事前登録は本日より開始いたします。リリース時期は今冬の予定。</p>
      <p>『麻雀一番街』では様々な公式大会を用意し、プレイヤー全員が参加できる事をめざします。是非ご注目ください！</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  margin: 20px 20px 50px 20px; 
  text-align: left;
  p{
    color: #ffffff;
    text-indent: 1em; 
    font-weight: 500;
    &.titleName{
      // font-family: 'Source Han Sans-bold';
      font-size: 32px;
      text-indent: 0em;
    }
    &.subtitleName{
      font-size: 26px;
      text-indent: 0em;
    }
  }
}
</style>