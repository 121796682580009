const krTourContentList = [
  {
    href: '/tournament/page01',
    image: '/static/img/tournament/headerImagePage01-1.jpeg',
    title: '『麻雀一番街』公式大会へようこそ！！',
    subtitle: '2021.11.30',
    button:'MORE',
    topTag: 'tour.p2'
  }
]
const otherTourContentList = (locale) => [
  {
    href: '/tournament/page03',
    image: `/static/img/tournament/page03/header_${locale}.png`,
    title: 'tour.p10',
    subtitle: '2023.07.04',
    button:'MORE',
    topTag: 'tour.p2'
  },
  {
    href: '/tournament/page02',
    image: `/static/img/tournament/page02/headerImagePage02-1_${locale}.png`,
    title: 'tour.p3',
    subtitle: '2022.06.23',
    button:'MORE',
    topTag: 'tour.p2'
  },
]

export const tourContentList = (locale) => {
  return locale === 'kr' ? krTourContentList :otherTourContentList(locale)
}


export const tourPage  = (locale) => [
  {
    code:'page01',
    image: '/static/img/tournament/headerImagePage01-1.jpeg',
    title: '『麻雀一番街』公式大会へようこそ！！',
    subtitle: '2021.11.30',
    middlePage: 'tourPage01',
    button:'',
    topTag: 'tour.p2'
  },
  {
    code:'page02',
    image: `/static/img/tournament/page02/headerImagePage02-1_${locale}.png`,
    title: 'tour.p3',
    subtitle: '2022.06.23',
    middlePage: 'tourPage02',
    button:'',
    topTag: 'tour.p2'
  },
  {
    code:'page03',
    image: `/static/img/tournament/page03/header_${locale}.png`,
    title: 'tour.p10',
    subtitle: '2023.07.04',
    middlePage: 'tourPage03',
    button:'',
    topTag: 'tour.p2'
  },
]
