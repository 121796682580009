import { getNewDetail } from "@/api/news";
import { translate } from "@/utils/utils";
import dayjs from "dayjs";
export default {
  data() {
    return {
      langPriority: ["en", "jp", "tc", "kr"],
      newsData: {},
      // mulLangData: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    translate(val) {
      if (this.newsData.nkey && typeof val === "object") {
        return val[this.$store.state.language];
      }
      return translate(val);
    },
    async init() {
      if (!this.newsId) {
        this.$router.push(this.listPagePath);
      }
      const res = await getNewDetail({
        nkey: this.newsId,
        token: this.$route.query.token || undefined,
      });
      if (res.data.code === 0) {
        const { nkey, release_at, type, en, jp, kr, tc, expression } =
          res.data.data;
        this.newsData = {
          ...res.data.data,
          code: nkey,
          title: {
            en: en.title,
            jp: jp.title,
            kr: kr.title,
            tc: tc.title,
          },
          image: {
            en: en.main_img,
            jp: jp.main_img,
            kr: kr.main_img,
            tc: tc.main_img,
          },
          content: {
            en: en.content,
            jp: jp.content,
            kr: kr.content,
            tc: tc.content,
          },
          subtitle: dayjs(release_at * 1000).format("YYYY.MM.DD"),
          topTag: this.$store.state.newsTagMap[type],
          middlePage: "newsPage",
          expression: JSON.parse(expression || "[]"),
        };
        return;
      }
      const isValidNewsId = !!this.news.find((x) => x.code === this.newsId);
      if (!isValidNewsId) {
        this.$router.push(this.listPagePath);
      }
    },
  },
  computed: {
    serverContent() {
      if (this.newsData.nkey) {
        const detail = this.newsData[this.$store.state.language];
        if (detail.title && detail.main_img && detail.content) return detail;
        // 当前语言没有内容时，按照优先级查找有内容的语言
        const hasContentLang = this.langPriority.find((lang) => {
          const { title, main_img, content } = this.newsData[lang];
          return title && main_img && content;
        });
        if (hasContentLang) {
          return this.newsData[hasContentLang];
        }
      }
      return {
        title: "",
        main_img: "",
        content: "",
      };
    },
    listPagePath() {
      return this.$route.name === "tournamentDetail" ? "/tournament" : "/news";
    },
  },
};
