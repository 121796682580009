<template>
  <div>
    <div class="contents">
      <div class="background"></div>
      <v-container>
        <div class="contentBackground">
          <div class="newsContent">
            <div class="newsImage">
              <img v-if="newsData.nkey" :src="serverContent.main_img" alt="" />
              <img v-else :src="newsSetting.image" />
            </div>
            <div class="newsIntro">
              <div class="top">
                <p v-if="newsData.nkey" class="titleName">
                  {{ serverContent.title }}
                </p>
                <p v-else class="titleName">
                  {{ translate(newsSetting.title) }}
                </p>
                <div class="innerMiddle">
                  <!-- mobile -->
                  <p class="hidden-md-and-up subtitleName">
                    {{ newsSetting.subtitle }}
                  </p>
                  <div class="hidden-md-and-up tagMobile">
                    <p>{{ translate(newsSetting.topTag) }}</p>
                  </div>
                  <div class="hidden-md-and-up headerLine"></div>
                  <!-- pc -->
                  <p class="hidden-sm-and-down subtitleName">
                    {{ newsSetting.subtitle }}
                  </p>
                  <div class="hidden-sm-and-down tag">
                    <p>{{ translate(newsSetting.topTag) }}</p>
                  </div>
                </div>
                <div class="hidden-sm-and-down headerLine"></div>
              </div>
              <div class="middle">
                <component
                  :is="newsSetting.middlePage"
                  :content="serverContent.content"
                />
              </div>
              <div v-if="newsSetting.button" class="bottom">
                <p>{{ newsSetting.button }}</p>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import newsDetail from "@/mixins/news/newsDetail.js";
import newsPage from "@/components/news/newsPage.vue";
import tourPage01 from "@/components/tournament/tourPage01.vue";
import tourPage02 from "@/components/tournament/tourPage02.vue";
import tourPage03 from "@/components/tournament/tourPage03.vue";
import { tourPage } from "../../configs/tour-content";
export default {
  components: {
    tourPage01,
    tourPage02,
    tourPage03,
    newsPage,
  },
  mixins: [newsDetail],
  data() {
    return {
      newsId: "",
    };
  },
  created() {
    this.newsId = this.$route.params.id;
  },
  computed: {
    news() {
      return tourPage(this.$i18n.locale);
    },
    // newsSetting() {
    //   if (!this.newsId) {
    //     return {};
    //   }
    //   return this.news.find((x) => x.code === this.newsId)|| {};
    // },
    newsSetting() {
      if (!this.newsId) {
        return {};
      }
      if (this.newsData.nkey) {
        return this.newsData;
      }
      return this.news.find((x) => x.code === this.newsId) || {};
    },
  },
  mounted() {
    if (
      this.$store.state.language === "kr" &&
      this.$route.params.id === "page02"
    ) {
      this.$router.replace("/tournament/page01");
      // this.$router.go();
    }
  },
  // watch:{
  //   'this.$store.state.language':{
  //     handle(oldV,newV){
  //       console.log(oldV);
  //       console.log(newV);
  //     }
  //   }
  // }
};
</script>
<style lang="scss" scoped>
.background {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: url("/static/img/common/contentBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
}

.header {
  position: relative;
  width: 100%;
  max-height: 500px;
  z-index: 3;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.container {
  max-width: 1200px;
}
.contentBackground {
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
}
.newsContent {
  height: 100%;
  margin-top: 30px;
  background-color: #38357d;
  position: relative;
  display: flex;
  flex-direction: column;
  .newsImage {
    width: 100%;
    img {
      padding: 20px;
      width: 100%;
      height: 100%;
    }
  }
  .newsIntro {
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 60px;
    .top {
      .titleName {
        color: #ffffff;
        font-size: 40px;
        margin-bottom: 0;
      }
      .headerLine {
        width: 100%;
        height: 0.1rem;
        background-color: #7d8dfd;
      }
      .innerMiddle {
        margin-bottom: 50px;
        .subtitleName {
          float: right;
          font-weight: 500;
          text-align: right;
          font-size: 30px;
          margin: 0;
          color: #7d8dfd;
        }
        .tag {
          float: left;
          text-align: center;
          width: 177px;
          height: 44px;
          background-image: url("/static/img/news/tagImage.png");
          background-repeat: no-repeat;
          background-size: cover;
          p {
            color: #ffffff;
            position: relative;
            margin: 10px auto;
          }
        }
      }
    }
    .bottom {
      position: relative;
      text-align: center;
      margin: 0 auto;
      background-image: url("/static/img/home/bottom/roleButton.png");
      background-repeat: no-repeat;
      background-size: contain;
      height: 70px;
      width: 220px;
      p {
        position: relative;
        margin: 0 auto;
        top: 7px;
        font-size: 30px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .flexBox {
    justify-content: center;
    flex-wrap: wrap;
  }
  .inline-display {
    .item {
      width: 180px;
    }
  }
  .newsContent {
    .newsIntro {
      .top {
        .titleName {
          font-size: 30px;
        }
        .subtitleName {
          font-size: 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 980px) {
  .contentBackground {
    // width: 450px;
    margin: 0 auto;
    .row {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .newsContent {
      margin-left: 20px;
      margin-right: 20px;
      .newsImage {
        img {
          margin: 0;
        }
      }
      .newsIntro {
        margin-top: 0;
        max-width: 90%;
        .top {
          .titleName {
            font-size: 25px;
          }
          .innerMiddle {
            .subtitleName {
              float: left;
            }
            .tagMobile {
              position: absolute;
              text-align: center;
              width: 177px;
              height: 44px;
              top: -20px;
              right: -10px;
              background-image: url("/static/img/news/tagImage.png");
              background-repeat: no-repeat;
              background-size: cover;
              p {
                color: #ffffff;
                position: relative;
                margin: 10px auto;
              }
            }
          }
        }
        .bottom {
          p {
            font-size: 25px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .contentBackground {
    // width: 380px;
    // .newsContent{
    //   height: 450px;
    // }
    .newsContent {
      .newsImage {
        flex: 0;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .inline-display {
    .item {
      width: 150px;
      font-size: 20px;
    }
  }
  .contentBackground {
    // width: 320px;
    // .newsContent{
    //   height: 400px;
    // }
    .newsContent {
      .newsIntro {
        .top {
          .innerMiddle {
            .tagMobile {
              top: -10px;
              width: 127px;
              height: 33px;
              p {
                margin: 5px auto;
              }
            }
          }
        }
      }
      .newsImage {
        flex: 0;
      }
    }
  }
}
</style>
