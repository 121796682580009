<template>
  <div class="content">
    <div>
      <pre>{{ $t('tour.p4')}}</pre>
      <pre>{{ $t('tour.p5')}}</pre>
    </div>
    <div>
      <p>{{ $t('tour.p6')}}</p>
      <p>{{ $t('tour.p7')}}</p>
       <i18n path="tour.p8" tag="pre">
        <a
          class="text-decoration-none"
          place="link"
          href="https://forms.gle/8rUTnvMsZ2WgitiR7"
        >
         https://forms.gle/8rUTnvMsZ2WgitiR7
        </a>
      </i18n>
      <pre>
        {{ $t('tour.p9') }}
      </pre>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  margin: 30px 20px;
  text-align: left;
  p, pre{
    margin-top: 20px;
    color: #ffffff;
    font-weight: 500;
    white-space: pre-line;
    &.titleName{
      font-weight: 500;
      font-size: 32px;
      text-indent: 0em;
    }
    &.subtitleName{
      font-size: 26px;
      text-indent: 0em;
    }
  }
  img{
    width: 100%;
    max-width: 768px;
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
    a{
      word-break: break-all;
    &.ytLink{
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
