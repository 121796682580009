<template>
  <div class="content">
    <div>
      <i18n path="tour.p11" tag="pre">
        <a
          class="text-decoration-none"
          place="link"
          href="mailto:riichicitysupport@mahjong-jp.com"
        >
          riichicitysupport@mahjong-jp.com
        </a>
      </i18n>
    </div>
    <br/>
    <div class="img_wrap">
      <img
        :src="`/static/img/tournament/page03/content_${$store.state.language}.jpg`"
        alt=""
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.content {
  margin: 30px 20px;
  text-align: left;
  pre {
    margin-top: 20px;
    color: #ffffff;
    font-weight: 500;
    white-space: pre-line;
  }
  .img_wrap {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 767px) {
  a {
    word-break: break-all;
  }
}
</style>
